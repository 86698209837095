<template>
  <div class="graph-container" :ref="name"></div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import mermaid from 'mermaid'

export default class MermaidChart extends Vue {
  @Prop() public readonly name!: string
  @Prop() public readonly graph!: string
  @Prop() public readonly onOnNodeSelected!: Event

  public mounted() {
    mermaid.initialize({})

    if(this.graph) {
      mermaid.render(`graph-${this.name}`, this.graph, this.insertSvg)
    }
  }

  public insertSvg(svg: string) {
    const graphContainer = this.$refs[this.name] as HTMLElement
    if(!graphContainer) {
      return
    }

    graphContainer.innerHTML = svg

    const pattern = /((\d)(?:[_\\.])(\d)(?:[_\\.])(\d))/
    const clickable = graphContainer.querySelectorAll('.clickable')
    for(const c of clickable) {
      const match = pattern.exec(c.id)
      if(!match) {
        continue
      }

      c.addEventListener('click', () => {
        const nodeName = match[1]
        const nodeId = nodeName.split(/[_\\.]/).join('.')

        this.$emit('onNodeSelected', nodeId)
      })
    }
  }
}
</script>

<style lang="scss">
.graph-container {
  margin: 0;
}
</style>
