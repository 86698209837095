<template>
  <div>
    <h1>Last 24 hours</h1>
    <div id="status-per-tenant">
      <table>
        <thead>
          <tr>
            <th>Failed</th>
            <th>Partially Succeeded</th>
            <th>Succeeded</th>
            <th>Tenant</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ this.failedPercentage }}%</td>
            <td>{{ this.partiallySucceededPercentage }}%</td>
            <td>{{ this.succeededPercentage }}%</td>
            <td>Overall</td>
            <td><staggered-bar :bar-data="staggeredBarData" /></td>
          </tr>
          <tr v-for="row in overviewData" :key="row.tenant">
            <td>{{ row.failedPercentage }}%</td>
            <td>{{ row.partiallySucceededPercentage }}%</td>
            <td>{{ row.succeededPercentage }}%</td>
            <td><router-link :to="row.tenantRoute">{{ row.tenant }}</router-link></td>
            <td><staggered-bar :bar-data="row.staggeredBarData" /></td>
          </tr>
        </tbody>
      </table>
    </div>

    <h1>Last 30 days</h1>
    <div id="thirty-day-trend-overall" v-if="thirtyDayTrendOverallData">
      <h2>Overall</h2>
      <table class="trend-table">
        <tbody>
          <tr>
            <td v-for="item in thirtyDayTrendOverallData" :key="item.timestamp">
              <staggered-bar direction='vertical' :bar-data="getBarData(item)" :style="getThirtyDayTrendStaggeredBarStyle(thirtyDayTrendOverallData, item)"/>
            </td>
          </tr>
          <tr>
            <td v-for="item in thirtyDayTrendOverallData" :key="item.timestamp">
              <span>{{ item.timestamp }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="thirty-day-trend-tenants" v-if="thirtyDayTrendDataForSelectedTenant">
      <h2>Per tenant</h2>
      <select v-model="selectedTenant">
        <option v-for="tenant in trendTenants" :key="tenant" :value="tenant">{{ tenant }}</option>
      </select>
      <table class="trend-table">
        <tbody>
          <tr>
            <td v-for="item in thirtyDayTrendDataForSelectedTenant" :key="item.timestamp">
              <staggered-bar direction='vertical' :bar-data="getBarData(item)" :style="getThirtyDayTrendStaggeredBarStyle(thirtyDayTrendDataForSelectedTenant, item)"/>
            </td>
          </tr>
          <tr>
            <td v-for="item in thirtyDayTrendDataForSelectedTenant" :key="item.timestamp">
              <span>{{ item.timestamp }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { store } from '@/store'
import { DataState } from '@/store/modules'
import { Vue } from 'vue-class-component'
import moment from 'moment'
import lodash from 'lodash'
import { PipelineListItem, ThirtyDayTrendItem, ThirtyDayTrendPerTenantItem } from '@/models'
import { DateTimeHelper, MathHelper } from '@/helpers'
import { StaggeredBarData } from '@/components/StaggeredBar.vue'

class OverViewRowViewModel {
  public tenant: string
  public failedPercentage: number
  public partiallySucceededPercentage: number
  public succeededPercentage: number
  public staggeredBarData: StaggeredBarData[]
  public tenantRoute: string

  constructor(tenant: string, data: PipelineListItem[]) {
    this.tenant = tenant

    const failed = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.failed, 0)
    const partiallySucceeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.partiallySucceeded, 0)
    const succeeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.succeeded, 0)
    const total = failed + partiallySucceeded + succeeded

    this.failedPercentage = Math.round(MathHelper.calcPercentage(failed, total))
    this.partiallySucceededPercentage = Math.round(MathHelper.calcPercentage(partiallySucceeded, total))
    this.succeededPercentage = Math.round(MathHelper.calcPercentage(succeeded, total))

    this.staggeredBarData = [
      new StaggeredBarData('#99cc66', succeeded),
      new StaggeredBarData('#f89e37', partiallySucceeded),
      new StaggeredBarData('#e0412b', failed)
    ]

    const from = moment().subtract(1, 'hour').valueOf()
    const to =  moment().add(1, 'hour').valueOf()

    this.tenantRoute = `/dashboard/${tenant}/${from}/${to}`
  }
}

export default class Dashboard extends Vue {
  public async mounted() {
    await store.dispatch('global/setSelectedTenant', 'All')

    store.commit('global/updateStartDate', moment().subtract(1, 'day').toDate())
    store.commit('global/updateEndDate', moment().toDate())

    await store.dispatch('data/loadData')
    await store.dispatch('data/loadThirtyDayTrendData')
    setTimeout(() => this.setOverallData(), 500)

    if(this.trendTenants) {
      this.selectedTenant = this.trendTenants[0]
    }
  }

  public staggeredBarData: StaggeredBarData[] | null = null
  public failedPercentage: number | null = null
  public partiallySucceededPercentage: number | null = null
  public succeededPercentage: number | null = null

  private get dataState(): DataState {
    return store.state.data
  }

  private setOverallData() {
    const data = this.dataState.pipelineListData?.pipelineListItems
    if(!data) {
      return
    }

    const failed = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.failed, 0)
    const partiallySucceeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.partiallySucceeded, 0)
    const succeeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.succeeded, 0)
    const total = failed + partiallySucceeded + succeeded

    this.failedPercentage = Math.round(MathHelper.calcPercentage(failed, total))
    this.partiallySucceededPercentage = Math.round(MathHelper.calcPercentage(partiallySucceeded, total))
    this.succeededPercentage = Math.round(MathHelper.calcPercentage(succeeded, total))

    this.staggeredBarData = [
      new StaggeredBarData('#99cc66', succeeded),
      new StaggeredBarData('#f89e37', partiallySucceeded),
      new StaggeredBarData('#e0412b', failed)
    ]
  }

  public get overviewData(): OverViewRowViewModel[] | null {
    const data = this.dataState.pipelineListData
    if(!data) {
      return null
    }

    const dataGroupedByTenantId = lodash.groupBy(data.pipelineListItems, (item: PipelineListItem) => item.tenantId)

    const result = new Array<OverViewRowViewModel>()
    for(const key in dataGroupedByTenantId) {
      result.push(new OverViewRowViewModel(key, dataGroupedByTenantId[key]))
    }

    return result.sort((a, b) => {
      if(a.tenant > b.tenant) {
        return 1
      }

      if(a.tenant < b.tenant) {
        return -1
      }

      return 0
    }).sort((a, b) => a.succeededPercentage - b.succeededPercentage)
  }

  public get thirtyDayTrendOverallData(): ThirtyDayTrendItem[] | null {
    return this.dataState.thirtyDayTrendData?.overall ?? null
  }

  public getBarData(item: ThirtyDayTrendItem): StaggeredBarData[] {
    return [
      new StaggeredBarData('#e0412b', item.failed),
      new StaggeredBarData('#f89e37', item.partiallySucceeded),
      new StaggeredBarData('#99cc66', item.succeeded)
    ]
  }

  public selectedTenant: string | null = null

  public get thirtyDayTrendPerTenantData(): ThirtyDayTrendPerTenantItem[] | null {
    return this.dataState.thirtyDayTrendData?.perTenant ?? null
  }

  public get trendTenants(): string[] | null {
    return this.thirtyDayTrendPerTenantData?.map(d => d.tenantId).filter((value, index, array) => array.indexOf(value) === index) ?? null
  }

  public get thirtyDayTrendDataForSelectedTenant(): ThirtyDayTrendPerTenantItem[] | null {
    return this.thirtyDayTrendPerTenantData?.filter(d => d.tenantId == this.selectedTenant) ?? null
  }

  public getThirtyDayTrendStaggeredBarStyle(items: ThirtyDayTrendItem[], item: ThirtyDayTrendItem) {
    const sorted = items.map(x => x.failed + x.partiallySucceeded + x.succeeded).sort(this.sortDesc)
    const highest = sorted[0]
    const itemTotal = item.failed + item.partiallySucceeded + item.succeeded
    const heightPercentage = MathHelper.calcPercentage(itemTotal, highest)

    return {
      height: `${heightPercentage}%`
    }
  }

  private sortDesc(a: number, b: number): number {
    if(a < b) {
      return 1
    }

    if(a > b) {
      return -1
    }

    return 0
  }
}
</script>

<style lang="scss">
#status-per-tenant {
  table {
    tr:nth-child(1) td {
      border-bottom: solid 1px #F09B27;
    }

    th, td {
      text-align: center;
    }

    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4) {
      width: 100px;
    }
  }
}

table.trend-table {
  max-width: 100%;

  & > tbody > tr {
    background: unset;

    &:hover {
      background: inherit;
    }

    &:nth-child(1) {
      td {
        height: 200px;
        vertical-align: bottom;

        .staggered-bar {
          width: 100%;
        }
      }
    }

    &:nth-child(2) {
      td > span {
        writing-mode: vertical-lr;
      }
    }

    & > td {
      padding: 4px 4px;
      text-align: center;

      &:nth-child(even) {
        background: #FAFAFA;
      }
    }
  }
}

#thirty-day-trend-tenants {
  select {
    margin-bottom: 16px;
  }
}
</style>
