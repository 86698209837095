<template>
  <div>
    <div class="jumbotron">
      <h1>{{ title }}</h1>
      <p>{{ body }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'

export default class ErrorView extends Vue {
  public get title(): string {
    return this.$route.query.title as string
  }

  public get body(): string {
    return this.$route.query.body as string
  }
}
</script>