import {
  DashboardData,
  PipelineDetail,
  PipelineExecutionDetail,
  PipelineExecutionList,
  PipelineList,
  ThirtyDayTrend
} from "@/models";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ErrorHandler } from "@/errorHandler";
import moment from "moment";

/**
 * Service for the dashboard API.
 */
export class ApiService {
  private client: AxiosInstance;

  public constructor() {
    this.client = axios.create({
      baseURL: `${location.origin}/api/Dashboard`
    });
  }

  private async executeRequest<T>(req: AxiosRequestConfig): Promise<T | null> {
    try {
      const result = await this.client.request<T>(req);

      if (typeof result.data === "object") {
        return JSON.parse(
          JSON.stringify(result.data),
          (_: string, value: any) => {
            const match = /^\d{4}(-\d{2})+T(\d{2}:?)+(\.\d+)?Z?/.exec(value);
            if (match) {
              return new Date(match[0]);
            }

            return value;
          }
        ) as T;
      }

      return result.data;
    } catch (error) {
      await ErrorHandler.handleError(error);
      return null;
    }
  }

  private async executeGet<T>(req: AxiosRequestConfig): Promise<T | null> {
    req.method = "GET";
    return await this.executeRequest(req);
  }

  /**
   * Get dashboard data.
   */
  public async getDashboardData(): Promise<DashboardData | null> {
    const req: AxiosRequestConfig = {
      url: "/"
    };

    return await this.executeGet<DashboardData>(req);
  }

  /**
   * Get pipelines data for the tenant and date filters.
   * @param tenantId The tenant to get the pipelines for.
   * @param startDate Start date for the date filter.
   * @param endDate End date for the date filter.
   */
  public async getPipelines(
    tenantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<PipelineList | null> {
    const req: AxiosRequestConfig = {
      url: `/tenants/${tenantId}/pipelines`,
      params: {
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf()
      }
    };

    return await this.executeGet<PipelineList>(req);
  }

  /**
   * Get the details of a pipeline.
   * @param tenantId Tenant the pipeline belongs to.
   * @param pipelineId Pipeline to get the details for.
   * @param startDate Start date for the date filter
   * @param endDate End date for the date filter.
   */
  public async getPipelineDetail(
    tenantId: string,
    pipelineId: string,
    startDate: Date,
    endDate: Date
  ): Promise<PipelineDetail | null> {
    const req: AxiosRequestConfig = {
      url: `/tenants/${tenantId}/pipelines/${pipelineId}`,
      params: {
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf()
      }
    };

    return await this.executeGet<PipelineDetail>(req);
  }

  /**
   * Get the executions for a pipeline.
   * @param tenantId Tenant the pipeline belongs to.
   * @param pipelineId Pipeline to get the executions for.
   * @param pipelineExecutionId Specific execution of pipeline to get the executions for.
   */
  public async getPipelineExecutions(
    tenantId: string,
    pipelineId: string,
    pipelineExecutionId: string
  ): Promise<PipelineExecutionList | null> {
    const req: AxiosRequestConfig = {
      url: `/tenants/${tenantId}/pipelines/${pipelineId}/executions`,
      params: {
        pipelineExecutionId
      }
    };

    return await this.executeGet<PipelineExecutionList>(req);
  }

  /**
   * Get the details of a pipeline execution.
   * @param tenantId Tenant the pipeline belongs to.
   * @param pipelineId Pipeline to get the execution details for.
   * @param pipelineExecutionId Specific execution of pipeline to get the execution details for.
   * @param startMessageId Start message of the execution.
   * @param completionMessageId End message of the execution.
   */
  public async getPipelineExecutionDetail(
    tenantId: string,
    pipelineId: string,
    pipelineExecutionId: string,
    startMessageId: string,
    completionMessageId: string
  ): Promise<PipelineExecutionDetail | null> {
    const req: AxiosRequestConfig = {
      url: `/tenants/${tenantId}/pipelines/${pipelineId}/executions/message`,
      params: {
        pipelineExecutionId,
        startMessageId,
        completionMessageId
      }
    };

    return await this.executeGet<PipelineExecutionDetail>(req);
  }

  /**
   * Get the thirty day trend data.
   */
  public async getThirtyDayTrend() {
    const req: AxiosRequestConfig = {
      url: "tenants/30d-trend"
    };

    return await this.executeGet<ThirtyDayTrend>(req);
  }

  /**
   * Get the documentation for a tenant.
   * @param tenantId Tenant the documentation belongs to.
   */
  public async getDocumentationFilenames(tenantId: string): Promise<string[] | null> {
    const req: AxiosRequestConfig = {
      baseURL: location.origin,
      url: `/docs/${tenantId}`
    };

    return await this.executeGet<string[]>(req);
  }

  /**
   * Check if the client is currently authorised.
   * @returns A boolean indicating whether we are authorised or not.
   */
  public async isAuth(): Promise<boolean | null> {
    const req: AxiosRequestConfig = {
      baseURL: location.origin,
      url: "/isauth"
    };

    return await this.executeGet<boolean>(req);
  }
}
