
import { store } from '@/store'
import { DataState } from '@/store/modules'
import { Vue } from 'vue-class-component'
import moment from 'moment'
import lodash from 'lodash'
import { PipelineListItem, ThirtyDayTrendItem, ThirtyDayTrendPerTenantItem } from '@/models'
import { DateTimeHelper, MathHelper } from '@/helpers'
import { StaggeredBarData } from '@/components/StaggeredBar.vue'

class OverViewRowViewModel {
  public tenant: string
  public failedPercentage: number
  public partiallySucceededPercentage: number
  public succeededPercentage: number
  public staggeredBarData: StaggeredBarData[]
  public tenantRoute: string

  constructor(tenant: string, data: PipelineListItem[]) {
    this.tenant = tenant

    const failed = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.failed, 0)
    const partiallySucceeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.partiallySucceeded, 0)
    const succeeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.succeeded, 0)
    const total = failed + partiallySucceeded + succeeded

    this.failedPercentage = Math.round(MathHelper.calcPercentage(failed, total))
    this.partiallySucceededPercentage = Math.round(MathHelper.calcPercentage(partiallySucceeded, total))
    this.succeededPercentage = Math.round(MathHelper.calcPercentage(succeeded, total))

    this.staggeredBarData = [
      new StaggeredBarData('#99cc66', succeeded),
      new StaggeredBarData('#f89e37', partiallySucceeded),
      new StaggeredBarData('#e0412b', failed)
    ]

    const from = moment().subtract(1, 'hour').valueOf()
    const to =  moment().add(1, 'hour').valueOf()

    this.tenantRoute = `/dashboard/${tenant}/${from}/${to}`
  }
}

export default class Dashboard extends Vue {
  public async mounted() {
    await store.dispatch('global/setSelectedTenant', 'All')

    store.commit('global/updateStartDate', moment().subtract(1, 'day').toDate())
    store.commit('global/updateEndDate', moment().toDate())

    await store.dispatch('data/loadData')
    await store.dispatch('data/loadThirtyDayTrendData')
    setTimeout(() => this.setOverallData(), 500)

    if(this.trendTenants) {
      this.selectedTenant = this.trendTenants[0]
    }
  }

  public staggeredBarData: StaggeredBarData[] | null = null
  public failedPercentage: number | null = null
  public partiallySucceededPercentage: number | null = null
  public succeededPercentage: number | null = null

  private get dataState(): DataState {
    return store.state.data
  }

  private setOverallData() {
    const data = this.dataState.pipelineListData?.pipelineListItems
    if(!data) {
      return
    }

    const failed = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.failed, 0)
    const partiallySucceeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.partiallySucceeded, 0)
    const succeeded = data.reduce((acc: number, cur: PipelineListItem) => acc += cur.succeeded, 0)
    const total = failed + partiallySucceeded + succeeded

    this.failedPercentage = Math.round(MathHelper.calcPercentage(failed, total))
    this.partiallySucceededPercentage = Math.round(MathHelper.calcPercentage(partiallySucceeded, total))
    this.succeededPercentage = Math.round(MathHelper.calcPercentage(succeeded, total))

    this.staggeredBarData = [
      new StaggeredBarData('#99cc66', succeeded),
      new StaggeredBarData('#f89e37', partiallySucceeded),
      new StaggeredBarData('#e0412b', failed)
    ]
  }

  public get overviewData(): OverViewRowViewModel[] | null {
    const data = this.dataState.pipelineListData
    if(!data) {
      return null
    }

    const dataGroupedByTenantId = lodash.groupBy(data.pipelineListItems, (item: PipelineListItem) => item.tenantId)

    const result = new Array<OverViewRowViewModel>()
    for(const key in dataGroupedByTenantId) {
      result.push(new OverViewRowViewModel(key, dataGroupedByTenantId[key]))
    }

    return result.sort((a, b) => {
      if(a.tenant > b.tenant) {
        return 1
      }

      if(a.tenant < b.tenant) {
        return -1
      }

      return 0
    }).sort((a, b) => a.succeededPercentage - b.succeededPercentage)
  }

  public get thirtyDayTrendOverallData(): ThirtyDayTrendItem[] | null {
    return this.dataState.thirtyDayTrendData?.overall ?? null
  }

  public getBarData(item: ThirtyDayTrendItem): StaggeredBarData[] {
    return [
      new StaggeredBarData('#e0412b', item.failed),
      new StaggeredBarData('#f89e37', item.partiallySucceeded),
      new StaggeredBarData('#99cc66', item.succeeded)
    ]
  }

  public selectedTenant: string | null = null

  public get thirtyDayTrendPerTenantData(): ThirtyDayTrendPerTenantItem[] | null {
    return this.dataState.thirtyDayTrendData?.perTenant ?? null
  }

  public get trendTenants(): string[] | null {
    return this.thirtyDayTrendPerTenantData?.map(d => d.tenantId).filter((value, index, array) => array.indexOf(value) === index) ?? null
  }

  public get thirtyDayTrendDataForSelectedTenant(): ThirtyDayTrendPerTenantItem[] | null {
    return this.thirtyDayTrendPerTenantData?.filter(d => d.tenantId == this.selectedTenant) ?? null
  }

  public getThirtyDayTrendStaggeredBarStyle(items: ThirtyDayTrendItem[], item: ThirtyDayTrendItem) {
    const sorted = items.map(x => x.failed + x.partiallySucceeded + x.succeeded).sort(this.sortDesc)
    const highest = sorted[0]
    const itemTotal = item.failed + item.partiallySucceeded + item.succeeded
    const heightPercentage = MathHelper.calcPercentage(itemTotal, highest)

    return {
      height: `${heightPercentage}%`
    }
  }

  private sortDesc(a: number, b: number): number {
    if(a < b) {
      return 1
    }

    if(a > b) {
      return -1
    }

    return 0
  }
}
