
import { CookieHelper } from '@/helpers'
import { store } from '@/store'
import { GlobalState } from '@/store/modules'
import { Vue } from 'vue-class-component'
import moment from "moment";

export default class NavBar extends Vue {
  private get globalState(): GlobalState {
    return store.state.global
  }

  public get username(): string {
    return this.globalState.userName
  }

  public get tenants(): string[] {
    return this.globalState.accessibleTenantIds
  }

  public get multipleTenantsAvailable(): boolean {
    return this.tenants.length > 1
  }

  public get accessToAllTenants(): boolean {
    return this.globalState.accessToAllTenants
  }

  public get selectedTenant(): string {
    return this.globalState.selectedTenant
  }

  public signoutOnClick() {
    CookieHelper.clearAllCookies()
    location.href = `${location.origin}/signout`
  }

  public showSubMenu: boolean = false

  public hoverStart() {
    this.showSubMenu = true
  }

  public hoverStop() {
    this.showSubMenu = false
  }

  public subMenuClick(tenant: string) {
    store.dispatch('global/setSelectedTenant', tenant).then(async () => {
      await this.$router.push(store.getters['global/routeBasedOnOptions']).then(async () => {
        store.commit('global/updateStartDate', moment().subtract(1, 'hour').valueOf())
        store.commit('global/updateEndDate', moment().add(1, 'hour').valueOf())
        await store.dispatch('data/loadData')
      })
    })
  }

  public overviewOnClick() {
    store.dispatch('global/setSelectedTenant', 'All').then(async () => {
      await this.$router.push('/dashboard').then(async () => {
        await store.dispatch('data/loadData')
      })
    })
  }

  public get currentRouteName(): string {
    return this.$route.name as string
  }
}
