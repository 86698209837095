import { Store } from 'vuex'
import { GlobalStateModule, DataModule } from './modules'

const store = new Store({
  modules: {
    global: GlobalStateModule,
    data: DataModule
  }
})

export { store }
