<template>
  <div class="documentation">
    <h1>{{ tenant }}</h1>

    <div id="interfaces">
      <h2>Interfaces</h2>
      <ul
        class="nomargin"
        v-for="(filename, index) in interfaceFileNames"
        :key="index"
      >
        <li>
          <a :href="'/docs/' + tenant + '/' + filename" target="_blank">{{
            filename.replace(".html", "")
          }}</a>
        </li>
      </ul>
    </div>

    <div id="components">
      <h2>Components</h2>
      <ul
        class="nomargin"
        v-for="(filename, index) in componentFileNames"
        :key="index"
      >
        <li>
          <a :href="'/docs/' + tenant + '/' + filename" target="_blank">{{
            filename.replace(".html", "")
          }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { store } from "@/store";
import { DataState, GlobalState } from "@/store/modules";

export default class Documentation extends Vue {
  public async mounted() {
    await store.dispatch("data/loadDocumentationFilenames");
  }

  private get globalState(): GlobalState {
    return store.state.global;
  }

  private get dataState(): DataState {
    return store.state.data;
  }

  public get tenant(): string {
    return this.globalState.selectedTenant;
  }

  public get interfaceFileNames(): string[] | null {
    if (this.dataState.documentationFilenames == null) return null;

    return this.dataState.documentationFilenames.filter(
      name => !name.startsWith("broker")
    );
  }

  public get componentFileNames(): string[] | null {
    if (this.dataState.documentationFilenames == null) return null;

    return this.dataState.documentationFilenames.filter(name =>
      name.startsWith("broker")
    );
  }

  public async created() {
    await store.dispatch("data/loadData");
  }
}
</script>

<style lang="scss">
.nomargin {
  padding: 0;
  margin: 0;
}
</style>
