export class MathHelper {
  /**
   * Calculate percentage x of y.
   * @param x Input to get percentage of.
   * @param y Input that represents 100%.
   */
  public static calcPercentage(x: number, y: number): number {
    if(x == 0) return 0;

    return (x / Math.max(x, y)) * 100.0;
  }
}
