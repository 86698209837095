
import { Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

export class SortableTableData {
  public id: number
  public data: string

  constructor(id: number, data: string) {
    this.id = id
    this.data = data
  }
}

export class SortableTableRow {
  public id: number
  public data: SortableTableData[]
  public object: object

  constructor(id: number, data: SortableTableData[], object: object) {
    this.id = id
    this.data = data
    this.object = object
  }
}

export default class SortableTable extends Vue {
  @Prop() public readonly tableData!: object[]
  @Prop() public readonly tableHeaders!: string[]
  @Prop() public readonly dataBinder!: (header: string, row: object) => string
  @Prop() public readonly onRowClick!: Event
  @Prop() public readonly searchColumn!: string
  @Prop() public readonly filterColumn!: string
  @Prop() public readonly filterSelectorOptions!: string[]
  @Prop() public readonly filterCallback!: (filter: string, row: object) => boolean

  public sortedHeader: string = ''
  public sortedDirection: string = 'desc'
  public boundTableData: SortableTableRow[] = []
  public searchInput: string = ''
  public selectedFilterOption: string = ''
  public selectedId: number | null = null

  public selectedRowClass(id: number) {
    if(id == this.selectedId) {
      return 'selected'
    }

    return null
  }

  public created() {
    this.boundTableData = this.bindTableData(this.tableData)

    if(this.filterSelectorOptions) {
      this.selectedFilterOption = this.filterSelectorOptions[0]
    }
  }

  public isSortedRow(header: string): boolean {
    return header == this.sortedHeader
  }

  public headerClass(header: string): string {
    if(this.isSortedRow(header)) {
      return `sorted ${this.sortedDirection}`
    }

    return ''
  }

  public bindTableData(tableData: object[]) {
    let rowId = 0
    return tableData.map(element => {
      let colId = 0
      const data = this.tableHeaders.map(header => {
        const result = this.dataBinder(header, element)
        return new SortableTableData(colId++, result)
      })

      return new SortableTableRow(rowId++, data, element)
    })
  }

  @Emit('rowClick') public rowClick(row: SortableTableRow) {
    this.selectedId = row.id
    return row.object
  }

  public sort(header: string) {
    if(header == this.sortedHeader) {
      this.sortedDirection = this.sortedDirection == 'asc' ? 'desc' : 'asc' 
    } else {
      this.sortedDirection = 'asc'
      this.sortedHeader = header
    }

    const sortedData = this.tableData.sort(this.sortFunc)
    this.boundTableData = this.bindTableData(sortedData)
  }

  private sortFunc(a: object, b: object): number {
    const aVal = this.dataBinder(this.sortedHeader, a)
    const bVal = this.dataBinder(this.sortedHeader, b)

    if(this.sortedDirection == 'asc') {
      return this.sortAsc(aVal, bVal)
    } else {
      return this.sortDesc(aVal, bVal)
    }
  }

  private sortAsc(aVal: string, bVal: string): number {
    if(aVal < bVal) {
      return -1
    }

    if(aVal > bVal) {
      return 1
    }

    return 0
  }

  private sortDesc(aVal: string, bVal: string): number {
    if(aVal > bVal) {
      return -1
    }

    if(aVal < bVal) {
      return 1
    }

    return 0
  }

  public onSearchEnter() {
    const input = this.searchInput.toLowerCase().trim()
    if(input == '') {
      this.boundTableData = this.bindTableData(this.tableData)
      return
    }

    const filteredData = this.tableData.filter(item => {
      const value = this.dataBinder(this.searchColumn, item).toLowerCase().trim()
      return value.indexOf(input) >= 0
    })

    this.boundTableData = this.bindTableData(filteredData)
  }

  public get filterOptions(): string[] {
    const values = this.tableData.map(item => this.dataBinder(this.filterColumn, item))
    return values.filter((item, index, array) => array.indexOf(item) == index).sort(this.sortAsc)
  }

  public onFilterChangeCallback() {
    if(!this.selectedFilterOption) {
      this.boundTableData = this.bindTableData(this.tableData)
      return
    }

    let filteredData: object[] = []
    if(this.filterCallback) {
      filteredData = this.tableData.filter(item => this.filterCallback(this.selectedFilterOption, item))
    } else {
      filteredData = this.tableData.filter(item => this.dataBinder(this.filterColumn, item) == this.selectedFilterOption)
    }

    this.boundTableData = this.bindTableData(filteredData)
  }
}
