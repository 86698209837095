
import { Vue } from "vue-class-component";
import { store } from "@/store";
import { DataState, GlobalState } from "@/store/modules";

export default class Documentation extends Vue {
  public async mounted() {
    await store.dispatch("data/loadDocumentationFilenames");
  }

  private get globalState(): GlobalState {
    return store.state.global;
  }

  private get dataState(): DataState {
    return store.state.data;
  }

  public get tenant(): string {
    return this.globalState.selectedTenant;
  }

  public get interfaceFileNames(): string[] | null {
    if (this.dataState.documentationFilenames == null) return null;

    return this.dataState.documentationFilenames.filter(
      name => !name.startsWith("broker")
    );
  }

  public get componentFileNames(): string[] | null {
    if (this.dataState.documentationFilenames == null) return null;

    return this.dataState.documentationFilenames.filter(name =>
      name.startsWith("broker")
    );
  }

  public async created() {
    await store.dispatch("data/loadData");
  }
}
