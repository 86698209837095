import { DateTimeHelper } from '@/helpers'
import { DashboardData } from '@/models'
import { apiService } from '@/services'
import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import moment from "moment";

export interface GlobalState {
  loadState: boolean
  noDataAvailable: boolean
  customerName: string | null
  environmentName: string | null
  tenantId: string | null
  accessibleTenantIds: string[]
  accessToAllTenants: boolean
  userName: string
  startDateLocal: Date
  endDateLocal: Date
  dashboardDataLoaded: boolean
  selectedTenant: string
  selectedPipelineId: string | null
  selectedPipelineExecutionId: string | null
  selectedStartMessageId: string | null
  selectedCompletionMessageId: string | null
}

@Module({ namespaced: true })
export class GlobalStateModule extends VuexModule<GlobalState> implements GlobalState {
  public get routeBasedOnOptions(): string {
    if(this.selectedTenant == 'All') {
      return '/dashboard'
    }

    let base = `/dashboard/${this.selectedTenant}/${moment(this.startDateLocal).valueOf()}/${moment(this.endDateLocal).valueOf()}`

    if(this.selectedPipelineId) {
      base += `/${this.selectedPipelineId}`
    }

    if(this.selectedPipelineExecutionId) {
      base += `/${this.selectedPipelineExecutionId}`
    }

    if(this.selectedStartMessageId && this.selectedCompletionMessageId) {
      base += `/${this.selectedStartMessageId}/${this.selectedCompletionMessageId}`
    }

    return base
  }

  @Mutation public loadStateFromRouteParams(params: Record<string, string | string[]>) {
    this.selectedTenant = params.tenant as string
    this.startDateLocal = moment(parseInt(params.from as string)).toDate()
    this.endDateLocal = moment(parseInt(params.to as string)).toDate()

    const pipelineId = params.pipelineId as string
    if(pipelineId) {
      this.selectedPipelineId = pipelineId
    } else {
      this.selectedPipelineId = null
    }

    const pipelineExecutionId = params.pipelineExecutionId as string
    if(pipelineExecutionId)  {
      this.selectedPipelineExecutionId = pipelineExecutionId
    } else {
      this.selectedPipelineExecutionId = null
    }

    const startMessageId = params.startMessageId as string
    const completionMessageId = params.completionMessageId as string
    if(startMessageId && completionMessageId) {
      this.selectedStartMessageId = startMessageId
      this.selectedCompletionMessageId = completionMessageId
    } else {
      this.selectedStartMessageId = null
      this.selectedCompletionMessageId = null
    }
  }

  public loadState: boolean = false
  public loadCount: number = 0

  @Mutation public setLoadState(value: boolean) {
    this.loadState = value
  }

  @Mutation public incrementLoadCount() {
    this.loadCount++
  }

  @Mutation public decrementLoadCount() {
    if(this.loadCount > 0) {
      this.loadCount--
    }
  }

  @Mutation public resetLoadCount() {
    this.loadCount = 0
  }

  @Action public startLoading() {
    this.context.commit('incrementLoadCount')
    this.context.commit('setLoadState', true)
  }

  @Action public stopLoading() {
    this.context.commit('decrementLoadCount')
    if(this.loadCount == 0) {
      this.context.commit('setLoadState', false)
    }
  }

  @Action public stopLoadingImmediate() {
    this.context.commit('resetLoadCount')
    this.context.commit('setLoadState', false)
  }

  public noDataAvailable: boolean = false

  @Mutation public setNoDataAvailable(value: boolean) {
    this.noDataAvailable = value
  }

  public customerName: string | null = null
  public environmentName: string | null = null
  public tenantId: string | null = null
  public accessibleTenantIds: string[] = []
  public userName: string = ''
  public accessToAllTenants: boolean = false

  @Mutation public setDashboardData(data: DashboardData) {
    this.customerName = data.customerName
    this.environmentName = data.environmentName
    this.tenantId = data.tenantId
    this.startDateLocal = data.startDate
    this.endDateLocal = data.endDate
    this.accessibleTenantIds = data.accessibleTenantIds
    this.userName = data.userName
    this.accessToAllTenants = data.hasAccessToAllTenants

    if(this.accessToAllTenants) {
      this.selectedTenant = 'All'
    } else {
      this.selectedTenant = this.tenantId
    }
  }

  public startDateLocal: Date = new Date()

  @Mutation public updateStartDate(value: Date) {
    this.startDateLocal = value
  }

  public endDateLocal: Date = new Date()

  @Mutation public updateEndDate(value: Date) {
    this.endDateLocal = value
  }

  public dashboardDataLoaded: boolean = false

  @Mutation public setDashboardDataLoaded(value: boolean) {
    this.dashboardDataLoaded = value
  }

  @Action public async loadDashboardData() {
    if(this.dashboardDataLoaded) {
      return
    }

    await this.context.dispatch('startLoading')

    const dashboardData = await apiService.getDashboardData()
    if(dashboardData?.accessibleTenantIds.length == 0) {
      this.context.commit('setNoDataAvailable', true)
      await this.context.dispatch('stopLoading')
      return
    }

    this.context.commit('setDashboardData', dashboardData)
    this.context.commit('setDashboardDataLoaded', true)
    this.context.commit('setNoDataAvailable', false)
    await this.context.dispatch('stopLoading')
  }

  public selectedTenant: string = 'All'

  @MutationAction public async setSelectedTenant(selectedTenant: string) {
    return {
      selectedTenant: selectedTenant,
      selectedPipelineId: '',
      selectedPipelineExecutionId: '',
      selectedStartMessageId: '',
      selectedCompletionMessageId: ''
    }
  }

  public selectedPipelineId: string | null = null

  @MutationAction public async setSelectedPipelineId(selectedPipelineId: string) {
    return {
      selectedPipelineId,
      selectedPipelineExecutionId: '',
      selectedStartMessageId: '',
      selectedCompletionMessageId: ''
    }
  }

  public selectedPipelineExecutionId: string | null = null

  @MutationAction public async setSelectedPipelineExectionId(selectedPipelineExecutionId: string) {
    return {
      selectedPipelineExecutionId,
      selectedStartMessageId: '',
      selectedCompletionMessageId: ''
    }
  }

  public selectedStartMessageId: string | null = null

  @MutationAction public async setSelectedStartMessageId(selectedStartMessageId: string) {
    return {
      selectedStartMessageId
    }
  }

  public selectedCompletionMessageId: string | null = null

  @MutationAction public async setSelectedCompletionMessageId(selectedCompletionMessageId: string) {
    return {
      selectedCompletionMessageId
    }
  }

  @Action public async resetData() {
    await this.context.dispatch('setSelectedPipelineId', null)
    await this.context.dispatch('setSelectedPipelineExectionId', null)
    await this.context.dispatch('setSelectedStartMessageId', null)
    await this.context.dispatch('setSelectedCompletionMessageId', null)
  }
}
