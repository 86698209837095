import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { apiService } from '@/services'
import { PipelineDetail, PipelineExecutionDetail, PipelineExecutionList, PipelineList, ThirtyDayTrend } from '@/models'
import { GlobalState } from './globalStateModule'

export interface DataState {
  pipelineListData: PipelineList | null;
  pipelineDetailData: PipelineDetail | null;
  pipelineExecutionsData: PipelineExecutionList | null;
  pipelineExecutionDetailData: PipelineExecutionDetail | null;
  thirtyDayTrendData: ThirtyDayTrend | null;
  documentationFilenames: string[] | null;
}

@Module({ namespaced: true })
export class DataModule extends VuexModule<DataState> implements DataState {
  private get globalState(): GlobalState {
    return this.context.rootState.global;
  }

  private currentTenantId: string | null = null;
  @Mutation public setCurrentTenantId(value: string) {
    this.currentTenantId = value;
  }

  private currentStartDate: Date | null = null;
  @Mutation public setCurrentStartDate(value: Date) {
    this.currentStartDate = value;
  }

  private currentEndDate: Date | null = null;
  @Mutation public setCurrentEndDate(value: Date) {
    this.currentEndDate = value;
  }

  private currentPipelineId: string | null = null;
  @Mutation public setCurrentPipelineId(value: string) {
    this.currentPipelineId = value;
  }

  private currentPipelineExecutionId: string | null = null;
  @Mutation public setCurrentPipelineExecutionId(value: string) {
    this.currentPipelineExecutionId = value;
  }

  private currentStartMessageId: string | null = null;
  @Mutation public setCurrentStartMessageId(value: string) {
    this.currentStartMessageId = value;
  }

  private currentCompletionMessageId: string | null = null;
  @Mutation public setCurrentCompletionMessageId(value: string) {
    this.currentCompletionMessageId = value;
  }

  @Action public async loadData() {
    await this.context.dispatch("global/startLoading", null, { root: true });

    if (
      this.globalState.selectedTenant != this.currentTenantId ||
      this.globalState.startDateLocal != this.currentStartDate ||
      this.globalState.endDateLocal != this.currentEndDate
    ) {
      await this.context.dispatch("loadPipelineListData");
    }

    if (
      this.globalState.selectedPipelineId &&
      this.globalState.selectedPipelineId != this.currentPipelineId
    ) {
      await this.context.dispatch("loadPipelineDetailData");
    }

    if (
      this.globalState.selectedPipelineExecutionId &&
      this.globalState.selectedPipelineExecutionId !=
        this.currentPipelineExecutionId
    ) {
      await this.context.dispatch("loadPipelineExecutionsData");
    }

    if (
      this.globalState.selectedStartMessageId &&
      this.globalState.selectedStartMessageId != this.currentStartMessageId &&
      this.globalState.selectedCompletionMessageId &&
      this.globalState.selectedCompletionMessageId !=
        this.currentCompletionMessageId
    ) {
      await this.context.dispatch("loadPipelineExecutionDetail");
    }

    await this.context.dispatch("global/stopLoading", null, { root: true });
  }

  public pipelineListData: PipelineList | null = null;

  @Action public async loadPipelineListData() {
    const tenantId = this.globalState.selectedTenant;
    const startDate = this.globalState.startDateLocal;
    const endDate = this.globalState.endDateLocal;
    this.context.commit("setPipelineListData", null);
    this.context.commit("setCurrentTenantId", tenantId);
    this.context.commit("setCurrentStartDate", startDate);
    this.context.commit("setCurrentEndDate", endDate);

    const pipelineListData = await apiService.getPipelines(
      tenantId,
      startDate,
      endDate
    );
    this.context.commit("setPipelineListData", pipelineListData);
    this.context.commit("setPipelineDetailData", null);
    this.context.commit("setPipelineExecutionsData", null);
    this.context.commit("setPipelineExecutionDetailData", null);
    this.context.commit("setCurrentPipelineId", null);
    this.context.commit("setCurrentPipelineExecutionId", null);
    this.context.commit("setCurrentStartMessageId", null);
    this.context.commit("setCurrentCompletionMessageId", null);
  }

  @Mutation public setPipelineListData(data: PipelineList | null) {
    this.pipelineListData = data;
  }

  public pipelineDetailData: PipelineDetail | null = null;

  @Action public async loadPipelineDetailData() {
    const tenantId = this.globalState.selectedTenant;
    const startDate = this.globalState.startDateLocal;
    const endDate = this.globalState.endDateLocal;
    const pipelineId = this.globalState.selectedPipelineId!;
    this.context.commit("setPipelineDetailData", null);
    this.context.commit("setCurrentTenantId", tenantId);
    this.context.commit("setCurrentStartDate", startDate);
    this.context.commit("setCurrentEndDate", endDate);
    this.context.commit("setCurrentPipelineId", pipelineId);

    const pipelineDetailData = await apiService.getPipelineDetail(
      tenantId,
      pipelineId,
      startDate,
      endDate
    );
    this.context.commit("setPipelineDetailData", pipelineDetailData);
    this.context.commit("setPipelineExecutionsData", null);
    this.context.commit("setPipelineExecutionDetailData", null);
    this.context.commit("setCurrentPipelineExecutionId", null);
    this.context.commit("setCurrentStartMessageId", null);
    this.context.commit("setCurrentCompletionMessageId", null);
  }

  @Mutation public setPipelineDetailData(data: PipelineDetail | null) {
    this.pipelineDetailData = data;
  }

  public pipelineExecutionsData: PipelineExecutionList | null = null;

  @Action public async loadPipelineExecutionsData() {
    const tenantId = this.globalState.selectedTenant;
    const pipelineId = this.globalState.selectedPipelineId!;
    const pipelineExecutionId = this.globalState.selectedPipelineExecutionId!;
    this.context.commit("setPipelineExecutionsData", null);
    this.context.commit("setCurrentTenantId", tenantId);
    this.context.commit("setCurrentPipelineId", pipelineId);
    this.context.commit("setCurrentPipelineExecutionId", pipelineExecutionId);

    const pipelineExecutionsData = await apiService.getPipelineExecutions(
      tenantId,
      pipelineId,
      pipelineExecutionId
    );

    this.context.commit("setPipelineExecutionsData", pipelineExecutionsData);
    this.context.commit("setPipelineExecutionDetailData", null);
    this.context.commit("setCurrentStartMessageId", null);
    this.context.commit("setCurrentCompletionMessageId", null);
  }

  @Mutation public setPipelineExecutionsData(
    data: PipelineExecutionList | null
  ) {
    this.pipelineExecutionsData = data;
  }

  public pipelineExecutionDetailData: PipelineExecutionDetail | null = null;

  @Action public async loadPipelineExecutionDetail() {
    const tenantId = this.globalState.selectedTenant;
    const pipelineId = this.globalState.selectedPipelineId!;
    const pipelineExecutionId = this.globalState.selectedPipelineExecutionId!;
    const startMessageId = this.globalState.selectedStartMessageId!;
    const completionMessageId = this.globalState.selectedCompletionMessageId!;
    this.context.commit("setPipelineExecutionDetailData", null);
    this.context.commit("setCurrentTenantId", tenantId);
    this.context.commit("setCurrentPipelineId", pipelineId);
    this.context.commit("setCurrentPipelineExecutionId", pipelineExecutionId);
    this.context.commit("setCurrentStartMessageId", startMessageId);
    this.context.commit("setCurrentCompletionMessageId", completionMessageId);

    const pipelineExecutionDetailData = await apiService.getPipelineExecutionDetail(
      tenantId,
      pipelineId,
      pipelineExecutionId,
      startMessageId,
      completionMessageId
    );
    this.context.commit(
      "setPipelineExecutionDetailData",
      pipelineExecutionDetailData
    );
  }

  @Mutation public setPipelineExecutionDetailData(
    data: PipelineExecutionDetail | null
  ) {
    this.pipelineExecutionDetailData = data;
  }

  public thirtyDayTrendData: ThirtyDayTrend | null = null;

  @Action public async loadThirtyDayTrendData() {
    await this.context.dispatch("global/startLoading", null, { root: true });
    const thirtyDayTrendData = await apiService.getThirtyDayTrend();
    this.context.commit("setThirtyDayTrendData", thirtyDayTrendData);
    await this.context.dispatch("global/stopLoading", null, { root: true });
  }

  @Mutation setThirtyDayTrendData(data: ThirtyDayTrend | null) {
    this.thirtyDayTrendData = data;
  }

  public documentationFilenames: string[] | null = null;

  @Action public async loadDocumentationFilenames() {
    const tenantId = this.globalState.selectedTenant;

    await this.context.dispatch("global/startLoading", null, { root: true });

    const documentationFilenames = await apiService.getDocumentationFilenames(
      tenantId
    );

    this.context.commit("setDocumentationFilenames", documentationFilenames);

    await this.context.dispatch("global/stopLoading", null, { root: true });
  }

  @Mutation setDocumentationFilenames(data: string[] | null) {
    this.documentationFilenames = data;
  }
}
