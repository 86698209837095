<template>
  <div id="nav-bar">
    <div class="container" :class="multipleTenantsAvailable ? 'higher' : null">
      <a href="/" class="brand">
        <img src="@/assets/logo_white.png" alt="" />
      </a>

      <div class="navigation">
         <div class="nav-items" v-if="multipleTenantsAvailable">
          <a v-if="accessToAllTenants" @click="overviewOnClick" :class="currentRouteName == 'Dashboard' ? 'selected' : null"><span>Overview</span></a>
          <a class="sub-menu" v-if="multipleTenantsAvailable" @mouseenter="hoverStart" @mouseleave="hoverStop" :class="currentRouteName == 'Tenant dashboard' ? 'selected' : null">
            <span>Tenants</span>
            <div class="nav-items" v-show="showSubMenu">
              <a v-for="tenant in tenants" :key="tenant" @click="subMenuClick(tenant)" :class="selectedTenant == tenant ? 'selected' : null"><span>{{ tenant }}</span></a>
            </div>
          </a>
        </div>

        <p class="username">{{ username }}</p>
        <a href="#" @click="signoutOnClick" class="signout-button">Sign Out</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CookieHelper } from '@/helpers'
import { store } from '@/store'
import { GlobalState } from '@/store/modules'
import { Vue } from 'vue-class-component'
import moment from "moment";

export default class NavBar extends Vue {
  private get globalState(): GlobalState {
    return store.state.global
  }

  public get username(): string {
    return this.globalState.userName
  }

  public get tenants(): string[] {
    return this.globalState.accessibleTenantIds
  }

  public get multipleTenantsAvailable(): boolean {
    return this.tenants.length > 1
  }

  public get accessToAllTenants(): boolean {
    return this.globalState.accessToAllTenants
  }

  public get selectedTenant(): string {
    return this.globalState.selectedTenant
  }

  public signoutOnClick() {
    CookieHelper.clearAllCookies()
    location.href = `${location.origin}/signout`
  }

  public showSubMenu: boolean = false

  public hoverStart() {
    this.showSubMenu = true
  }

  public hoverStop() {
    this.showSubMenu = false
  }

  public subMenuClick(tenant: string) {
    store.dispatch('global/setSelectedTenant', tenant).then(async () => {
      await this.$router.push(store.getters['global/routeBasedOnOptions']).then(async () => {
        store.commit('global/updateStartDate', moment().subtract(1, 'hour').valueOf())
        store.commit('global/updateEndDate', moment().add(1, 'hour').valueOf())
        await store.dispatch('data/loadData')
      })
    })
  }

  public overviewOnClick() {
    store.dispatch('global/setSelectedTenant', 'All').then(async () => {
      await this.$router.push('/dashboard').then(async () => {
        await store.dispatch('data/loadData')
      })
    })
  }

  public get currentRouteName(): string {
    return this.$route.name as string
  }
}
</script>

<style lang="scss">
#nav-bar {
  width: 100%;
  background-color: #231f20;
  justify-items: center;
  justify-content: center;
  display: flex;

  & > div:first-child {
    display: flex;
    flex-direction: row;
    height: 56px;
    padding: 0 16px;
    align-items: center;

    &.higher {
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      .navigation {
        width: 100%;
        padding-left: 0;
        max-height: 56px;

        a {
          border-left: none;
        }
      }

      .brand {
        min-height: 45px;
        padding-top: 20px;
        margin-left: 0;
      }

      .nav-items {
        top: 126px !important;
      }
    }

    p {
      color: white;
    }
  }

  .brand {
    padding: 5px 0;
    margin-right: 32px;

    img {
      margin-top: 2px;
      height: 24px;
    }
  }

  .tenant {
    margin-right: 8px;
  }

  .tenant-selector {
    width: fit-content;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    height: 56px;
    padding: 0 16px;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    justify-content: flex-end;
  }

  .navigation > .nav-items {
    display: flex;
    flex-grow: 1;
    height: 100%;

    a {
      display: flex;
      align-content: center;
      align-items: center;
      color: white;
      height: 100%;
      padding: 0 16px;
      cursor: pointer;
      text-transform: uppercase;
      line-height: 1.6rem;

      & > span {
        border-bottom: solid 2px transparent;
      }

      &.selected {
        background-color: white;
        color: black;

        &:hover > span {
          border-bottom: solid 2px black;
        }
      }

      &:not(.selected) {
        &:hover {
          background-color: #373737;

          & > span {
            border-bottom: solid 2px white;
          }
        }
      }
    }

    .sub-menu {
      cursor: default;

      .nav-items {
        display: flex;
        flex-direction: column;
        top: 56px;
        margin-left: -17px;
        position: absolute;

        a {
          display: flex;
          justify-content: center;
          border: none;
          height: 56px;
          min-width: 71px;
          width: inherit;
          background-color: #231f20;
          text-transform: uppercase;

          & > span {
            border-bottom: solid 2px transparent;
          }



          &:hover {
            color: white;
            background-color: #373737;

            & > span {
              border-bottom: solid 2px white;
            }
          }

          &.selected {
            color: white;
            background-color: #858585;

            & > span:hover {
              border-bottom: solid 2px white;
            }
          }
        }
      }
    }
  }

  .signout-button {
    margin-left: 24px;
    text-decoration: none;
    justify-self: flex-end;
    background-color: #373737;
    padding: 8px 16px;
    color: #858585;
    text-transform: uppercase;

    &:hover {
      background-color: #858585;
      color: white;
    }
  }
}
</style>
