import { store } from '@/store'
import { NavigationGuard } from './navigationGuard'

export const dataAvailableGuard: NavigationGuard = async (_, __, next) => {
  if(store.state.global.noDataAvailable) {
    next({ name: 'error', query: {
      title: 'No data available',
      body: 'Please try again at a later time.'
    }})
    return true
  }

  return false
}