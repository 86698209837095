import { createApp } from 'vue'
import App from '@/App.vue'
import { router } from '@/router'
import { store } from '@/store'

export const app = createApp(App).use(store).use(router)

import LoadOverlay from '@/components/LoadOverlay.vue'
app.component('load-overlay', LoadOverlay)

import NavBar from '@/components/NavBar.vue'
app.component('nav-bar', NavBar)

import DateTimePicker from '@/components/DateTimePicker.vue'
app.component('date-time-picker', DateTimePicker)

import SortableTable from '@/components/SortableTable.vue'
app.component('sortable-table', SortableTable)

import StaggeredBar from '@/components/StaggeredBar.vue'
app.component('staggered-bar', StaggeredBar)

import MermaidChart from '@/components/MermaidChart.vue'
app.component('mermaid-chart', MermaidChart)

app.mount('#app')