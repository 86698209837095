import moment from 'moment'

export class DateTimeHelper {
  private static pad(number: number): string{
    if (number < 10){
      return '0' + number
    }

    return number.toString();
  }

  /**
   * Format a date as UTC.
   * @param date The date to format.
   */
  public static formatDateUtc(date: Date): string {
    return date.getUTCFullYear() +
      '-' + DateTimeHelper.pad(date.getUTCMonth() + 1) +
      '-' + DateTimeHelper.pad(date.getUTCDate()) +
      ' ' + DateTimeHelper.pad(date.getUTCHours()) +
      ':' + DateTimeHelper.pad(date.getUTCMinutes()) +
      ':' + DateTimeHelper.pad(date.getUTCSeconds()) +
      '.' + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
  }

  /**
   * Format a date as local timezone.
   * @param date The date to format.
   */
  public static formatDateLocal(date: Date): string {
    return new Intl.DateTimeFormat('default', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
      hour12: false
    }).format(date)
  }
}
