import { store } from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import RedirectToLogin from "@/views/RedirectToLogin.vue";
import Dashboard from "@/views/Dashboard.vue";
import TenantDashboard from "@/views/TenantDashboard.vue";
import Documentation from "@/views/Documentation.vue";
import Error from "@/views/Error.vue";
import { routeGuards } from "./guards";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/redirect-to-login",
    name: "Redirect to login",
    component: RedirectToLogin
  },
  {
    path: "/error",
    name: "error",
    component: Error
  },
  {
    path:
      "/dashboard/:tenant/:from/:to/:pipelineId?/:pipelineExecutionId?/:startMessageId?/:completionMessageId?",
    name: "Tenant dashboard",
    component: TenantDashboard,
    beforeEnter: to => {
      store.commit("global/loadStateFromRouteParams", to.params);
    }
  },
  {
    path:
      "/dashboard/docs/:tenant",
    name: "Documentation",
    component: Documentation,
    beforeEnter: to => {
      store.commit("global/loadStateFromRouteParams", to.params);
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.name == "error") {
    next();
    return;
  }

  await store.dispatch("global/startLoading");

  if (to.path != "/redirect-to-login") {
    await store.dispatch("global/loadDashboardData");
  }

  // If a guard returns true then it has called 'next(redirect)' and we need to end further processing
  for (const guard of routeGuards) {
    if (await guard(to, from, next)) {
      await store.dispatch("global/stopLoading");
      return;
    }
  }

  next();
  await store.dispatch("global/stopLoading");
});

export { router };
