<template>
  <nav-bar v-if="hideNavBar"/>
  <div class="container">
    <router-view id="view" />
    <load-overlay />
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'

export default class App extends Vue {
  public get hideNavBar(): boolean {
    return this.$route.path !== '/redirect-to-login'
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
  overflow-x: hidden;
}

#app {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  max-width: 100vw;
}

#view {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 1110px;
  padding: 0 15px;
}

h1 {
  padding: 0;
  margin: 24px 0;
}

h2 {
  padding: 0;
  margin: 48px 0 12px;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;


  small {
    font-size: inherit;
    color: grey;
    font-weight: normal;
  }
}

a {
  color: #80151D;
  text-decoration: none;
}

table {
  border-spacing: 0;
  width: 100%;

  th, td {
    padding: .75rem;
    margin: 0;
  }

  td {
    border-top: 1px solid #dee2e6;
  }

  th {
    border-bottom: 2px solid #F09B27;
    user-select: none;
  }

  tbody {
    tr:nth-child(even) {
      background-color: #FAFAFA;
    }

    tr:hover {
      background-color: #e8ebee;
    }

    tr.selected {
      background-color: #F89E37;
    }
  }
}

.jumbotron {
  display: flex;
  flex-direction: column;
  background-color: #e9ecef;
  height: 100%;
  padding: 4rem 2rem;
  margin-top: 3rem;

  h1 {
    margin-top: 0;
    font-weight: 400;
    line-height: 1.2;
    font-size: 4.5rem;
    margin-bottom: .5rem;
  }

  p {
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

button {
  color: white;
  background-color: #e0412b;
  border: 1px solid #e0412b;
  padding: 8px 24px;
  cursor: pointer;
  height: 34px;
  text-transform: uppercase;

  &.focus {
    border: none;
  }
}

input,
select {
  font-family: inherit;
  padding: 6px 16px;
  border-radius: 0;
  border: 1px solid black;
  margin-right: 4px;
  box-sizing: border-box;
  height: 34px;
}

select, option {
  font: -moz-pull-down-menu;
}
</style>
