<template>
  <div id="load-overlay" v-show="loadState">
    <h1>Loading, please wait</h1>
    <div class="spinner"></div>
  </div>
</template>

<script lang="ts">
import { store } from '@/store'
import { Vue } from 'vue-class-component'

export default class LoadOverlay extends Vue {
  public get loadState(): boolean {
    return store.state.global.loadState
  }
}
</script>

<style lang="scss">
#load-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: wait;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    display: flex;
    color: white;
  }

  .spinner,
  .spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ef4e35;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
