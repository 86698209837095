<template>
  <div class="tenant-dashboard">
    <h1 id="tenant-name">{{ tenant }}</h1>

    <div id="documentation-link">
      <a :href="'/dashboard/docs/' + tenant" target="_blank">Click here to view the documentation.</a>
    </div>
    
    <div class="top-bar">
      <div class="date-time-filter">
        <date-time-picker label="From (UTC)" v-model:date-time="filterDateTimeFrom" :min="fromMinDate" :max="fromMaxDate"/>
        <date-time-picker label="To (UTC)" v-model:date-time="filterDateTimeTo" :min="toMinDate"/>
        <button @click="onFilterCLick">Filter</button>
      </div>

      <div class="utc-offset">
        <span>{{ utcOffset }}</span>
      </div>
    </div>

    <div v-if="!dataAvailable">
      <h1>No data for the selected filter.</h1>
    </div>

    <div class="overall-status" v-if="dataAvailable">
      <h2>Overall Status</h2>
      <staggered-bar :bar-data="overallStatusData" />
    </div>

    <div id="pipelines" v-if="dataAvailable">
      <h2>Pipeline Execution Events</h2>
      <sortable-table
        class="pipelines-table"
        v-if="pipelineTableData"
        :table-headers="['Pipeline Id', 'Triggered', 'Started', 'Prevented', 'Succeeded', 'Partially Succeeded', 'Failed']"
        :data-binder="pipelineTableDataBinder"
        :table-data="pipelineTableData"
        search-column="Pipeline Id"
        :filter-selector-options="['Filter by event', 'Triggered', 'Started', 'Prevented', 'Succeeded', 'Partially Succeeded', 'Failed']"
        :filter-callback="pipelinesFilterCallback"
        @rowClick="pipelinesOnRowClick" />
    </div>

    <div id="pipeline-details" ref="pipelineDetails" v-if="selectedPipelineId">
      <h2>Pipeline execution details: <small>{{ selectedPipelineId }}</small></h2>
      <sortable-table
        class="pipelines-detail-table"
        v-if="pipelineDetailTableData"
        :table-headers="['Pipeline Execution Id', 'Triggered (UTC)', 'Last event (UTC)', 'Last event', 'Status']"
        :data-binder="pipelineDetailTableDataBinder"
        :table-data="pipelineDetailTableData"
        @rowClick="pipelineDetailOnRowClick"
        filter-column="Status" />
    </div>

    <div id="pipeline-executions" ref="pipelineExecutions" v-if="pipelineExecutionsData">
      <h2>Execution details: <small>{{ selectedPipelineExecutionId }}</small></h2>
      <mermaid-chart v-if="flowchartData" name="pipeline-execution-details-flowchart" :graph="flowchartData" @onNodeSelected="onChartNodeSelected" />
      <mermaid-chart v-if="ganttchartData" name="pipeline-execution-details-ganttchart" :graph="ganttchartData" @onNodeSelected="onChartNodeSelected" />
      <sortable-table
        class="pipeline-executions-table"
        v-if="pipelineExecutionsTableData"
        :table-headers="['Start Timestamp (UTC)', 'End Timestamp (UTC)', 'Status', 'Node', 'Component']"
        :data-binder="pipelineExecutionsTableDataBinder"
        :table-data="pipelineExecutionsTableData"
        @rowClick="pipelineExecutionsOnRowClick" />
    </div>

    <div id="step-details" ref="stepDetails" v-if="pipelineExecutionDetailData">
      <h2>
        Step details
        <template v-if="remoteTaskExecutionId">:
          <small>{{ remoteTaskExecutionId }}</small>
        </template>
      </h2>

      <div v-if="pipelineExecutionDetailData.message.length > 0">
        <h3>Message</h3>
        <p><pre>{{ pipelineExecutionDetailData.message }}</pre></p>
      </div>

      <div v-if="pipelineExecutionDetailData.data.length > 0">
        <h3>Data</h3>
        <table>
          <thead>
            <tr>
              <th>Direction</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in pipelineExecutionDetailData.data" :key="`${row.direction}${row.Type}`">
              <td>{{ row.direction }}</td>
              <td>{{ row.type }}</td>
              <td><pre>{{ row.value }}</pre></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="pipelineExecutionDetailData.settings.length > 0">
        <h3>Settings</h3>
        <table>
          <thead>
            <tr>
              <th>Direction</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in pipelineExecutionDetailData.settings" :key="`${row.direction}${row.Type}`">
              <td>{{ row.direction }}</td>
              <td>{{ row.type }}</td>
              <td><pre>{{ row.value }}</pre></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="pipelineExecutionDetailData.metrics.length > 0">
        <h3>Metrics</h3>
        <table>
          <thead>
            <tr>
              <th>Direction</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in pipelineExecutionDetailData.metrics" :key="`${row.direction}${row.Type}`">
              <td>{{ row.direction }}</td>
              <td>{{ row.type }}</td>
              <td><pre>{{ row.value }}</pre></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button id="scroll-to-top-button" @click="backToTopOnClick">Back to top</button>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { store } from '@/store'
import { PipelineDetailItem, PipelineExecution, PipelineExecutionItem, PipelineExecutionList, PipelineListItem } from '@/models'
import { StaggeredBarData } from '@/components/StaggeredBar.vue'
import lodash from 'lodash'
import moment from 'moment'
import { DateTimeHelper } from '@/helpers'
import { DataState, GlobalState } from '@/store/modules'

export default class TenantDashboard extends Vue {
  private get globalState(): GlobalState {
    return store.state.global
  }

  private get dataState(): DataState {
    return store.state.data
  }

  public get tenant(): string {
    return this.globalState.selectedTenant
  }

  public get utcOffset(): string {
    const offset = moment().utcOffset()
    const offsetHours = offset / 60
    return `You are ${offsetHours} hour${(offsetHours < -1 || offsetHours > 1) ? 's' : ''} ${offsetHours > 0 ? 'ahead' : 'behind'} of UTC`
  }

  public get filterDateTimeFrom(): Date {
    return this.globalState.startDateLocal
  }

  public set filterDateTimeFrom(value: Date) {
    if(value > this.globalState.endDateLocal) {
      store.commit('global/updateStartDate', this.globalState.endDateLocal)
      return
    }

    store.commit('global/updateStartDate', value)
  }

  public get filterDateTimeTo(): Date {
    return this.globalState.endDateLocal
  }

  public set filterDateTimeTo(value: Date) {
    if(value < this.globalState.startDateLocal) {
      store.commit('global/updateEndDate', this.globalState.startDateLocal)
      return
    }

    store.commit('global/updateEndDate', value)
  }

  public get fromMinDate(): Date {
    return moment(this.globalState.startDateLocal).subtract(1, 'month').toDate()
  }

  public get toMinDate(): Date {
    return this.globalState.startDateLocal
  }

  public get fromMaxDate(): Date {
    return this.globalState.endDateLocal
  }

  public get toMaxDate(): Date {
    return new Date()
  }

  public get dataAvailable(): boolean {
    return (this.dataState.pipelineListData?.pipelineListItems.length ?? 0) > 0
  }

  public get overallStatusData(): StaggeredBarData[] | null {
    const stats = this.dataState.pipelineListData?.stats
    if(!stats) {
      return null
    }

    return [
      new StaggeredBarData('#99cc66', stats.succeeded),
      new StaggeredBarData('#f89e37', stats.partiallySucceeded),
      new StaggeredBarData('#e0412b', stats.failed)
    ]
  }

  public get pipelineTableData(): PipelineListItem[] | null {
    return this.dataState.pipelineListData?.pipelineListItems ?? null
  }

  public pipelineTableDataBinder(header: string, item: PipelineListItem) {
    switch (header) {
      case 'Pipeline Id': return item.pipelineId
      case 'Triggered': return item.triggered
      case 'Started': return item.started
      case 'Prevented': return item.prevented
      case 'Succeeded': return item.succeeded
      case 'Partially Succeeded': return item.partiallySucceeded
      case 'Failed': return item.failed
      default: return ''
    }
  }

  private scrollToRef(ref: string) {
    const element = this.$refs[ref] as HTMLElement
    element.scrollIntoView()
  }

  public async pipelinesOnRowClick(item: PipelineListItem) {
    await store.dispatch('global/setSelectedPipelineId', item.pipelineId)
    await this.reloadData()
    this.scrollToRef('pipelineDetails')
  }

  public get pipelineDetailTableData(): PipelineDetailItem[] | null {
    return this.dataState.pipelineDetailData?.pipelineDetailList ?? null
  }

  public pipelineDetailTableDataBinder(header: string, item: PipelineDetailItem) {
    switch(header) {
      case 'Pipeline Execution Id': return item.pipelineExecutionId
      case 'Triggered (UTC)': return DateTimeHelper.formatDateUtc(item.triggeredUtc)
      case 'Last event (UTC)': return DateTimeHelper.formatDateUtc(item.lastEventUtc)
      case 'Last event': return item.lastEvent
      case 'Status': return item.status
      default: return ''
    }
  }
  
  public async onFilterCLick() {
    await store.dispatch('global/resetData')
    await this.reloadData()
  }

  public async reloadData() {
    await this.$router.push(store.getters['global/routeBasedOnOptions']).then(async () => {
      await store.dispatch('data/loadData')
    })
  }

  public get selectedPipelineId(): string | null {
    return this.globalState.selectedPipelineId
  }

  public pipelinesFilterCallback(option: string, item: PipelineListItem) {
    switch(option) {
      case 'Triggered': return item.triggered > 0
      case 'Started': return item.started > 0
      case 'Prevented': return item.prevented > 0
      case 'Succeeded': return item.succeeded > 0
      case 'Partially Succeeded': return item.partiallySucceeded > 0
      case 'Failed': return item.failed > 0
      default: return true
    }
  }

  public async pipelineDetailOnRowClick(item: PipelineDetailItem) {
    await store.dispatch('global/setSelectedPipelineExectionId', item.pipelineExecutionId)
    await this.reloadData()
    this.scrollToRef('pipelineExecutions')
  }

  public get selectedPipelineExecutionId() {
    return this.globalState.selectedPipelineExecutionId
  }

  public get pipelineExecutionsData(): PipelineExecutionList | null {
    return this.dataState.pipelineExecutionsData
  }

  public get flowchartData(): string | null {
    const colours = new Map<string, string>([
      ['Succeeded', '#99cc66'],
      ['PartiallySucceeded', '#f89e37'],
      ['Failed', '#e0412b'],

      ['Pipeline_Succeeded', '#e6f2d9'],
      ['Pipeline_PartiallSucceeded', '#fde7ce'],
      ['Pipeline_Failed', '#f8d7d3']
    ])

    const data = this.pipelineExecutionsData
    if(!data) {
      return null
    }

    const graphLines = data.graph.split('\n').filter((value: string) => !!value)
    const pipelineStatus = data.executionList.find((value: PipelineExecutionItem) => value.nodeId == 'n/a')?.status ?? 'Running'

    let graph = [
      graphLines[0],
      `subgraph ${pipelineStatus}`,
      graphLines.slice(1).join('\n'),
      'end'
    ].join('\n')

    const pipelineStatusColour = `Pipeline_${pipelineStatus}`
    graph += '\n\tclassDef default fill:#ededed,stroke:#000;'
    graph += `\n\tclassDef pipelinestatus fill:${colours.get(pipelineStatusColour)},stroke:${colours.get(pipelineStatusColour)};`
    graph += `\n\tclass ${pipelineStatus} pipelinestatus`

    const groupedExecutions = lodash.chain(data.executionList)
      .filter('status')
      .filter('nodeId')
      .groupBy('nodeId')
      .reduce((result: PipelineExecutionItem[], value, key) => {
        const uniqueStatusses = lodash.uniqBy(value, 'status').length

        result.push({
          nodeId: key,
          status: uniqueStatusses == 1 ? value[0].status : 'PartiallySucceeded',
          startUtc: new Date(),
          completionUtc: null,
          componentId: '',
          startMessageId: '',
          completionMessageId: '',
          instanceId: ''
        })

        return result
      }, new Array<PipelineExecutionItem>())
      .groupBy('status')
      .value()

    for(const key in groupedExecutions) {
      const items = groupedExecutions[key]
      const nodeIds = lodash.chain(items).reduce((result: string[], item: PipelineExecutionItem) => {
        result.push(`node_${item.nodeId.replace(/\./g, '_')}`)
        return result
      }, new Array<string>())
      .value()
      .join()

      graph += `\n\tclick ${nodeIds} callback;`
      graph += `\n\tclassDef ${key} fill:${colours.get(key)},stroke:#000;`
      graph += `\n\tclass ${nodeIds} ${key}`
    }

    return graph
  }

  public get ganttchartData(): string | null {
    const executions = this.pipelineExecutionsData?.executionList

    if(!executions) {
      return null
    }

    let graph = 'gantt\n\tdateFormat HH:mm:ss\n\taxisFormat %H:%M:%S\n\ttodayMarker off'

    const groupedExecutions = lodash.chain(executions).filter('componentId').filter('instanceId').groupBy('instanceId').value()

    let counter = 0
    for(const key in groupedExecutions) {
      graph += `\n\tSection ${counter++}`

      const items = groupedExecutions[key]
      const filteredItems = items.filter(item => item.nodeId != 'n/a')
      for(const item of filteredItems) {
        const startTime = moment(item.startUtc)
        const endTime = moment(item.completionUtc ?? new Date())

        const duration = moment.duration(moment(endTime).diff(startTime)).asSeconds() | 0

        graph += `\n\t${item.componentId}:${item.nodeId},${startTime.format('HH:mm:ss')}, ${duration}s`
      }
    }

    const nodeIds = lodash
      .chain(executions)
      .reduce((result: string[], item: PipelineExecutionItem) => {
        result.push(item.nodeId)
        return result
      }, new Array<string>())
      .uniqBy(value => value)
      .join(',')
      .value()

    graph += `\n\tclick ${nodeIds} call callback()`

    return graph
  }

  public async onChartNodeSelected(nodeId: string) {
    const item = this.pipelineExecutionsData?.executionList.find(item => item.nodeId == nodeId)
    if(item) {
      await this.pipelineExecutionSelected(item)
    }
  }

  public get pipelineExecutionsTableData(): PipelineExecutionItem[] | null {
    return this.pipelineExecutionsData?.executionList ?? null
  }

  public pipelineExecutionsTableDataBinder(header: string, item: PipelineExecutionItem) {
    switch(header) {
      case 'Start Timestamp (UTC)': return DateTimeHelper.formatDateUtc(item.startUtc)
      case 'End Timestamp (UTC)': return !item.completionUtc ? '' : DateTimeHelper.formatDateUtc(item.completionUtc)
      case 'Status': return item.status
      case 'Node': return item.nodeId
      case 'Component': return item.componentId
      default: return ''
    }
  }

  public async pipelineExecutionsOnRowClick(item: PipelineExecutionItem) {
    await this.pipelineExecutionSelected(item)
  }

  public async pipelineExecutionSelected(item: PipelineExecutionItem) {
    await store.dispatch('global/setSelectedStartMessageId', item.startMessageId)
    await store.dispatch('global/setSelectedCompletionMessageId', item.completionMessageId)
    await this.reloadData()
    this.scrollToRef('stepDetails')
  }

  public get pipelineExecutionDetailData(): PipelineExecution | null {
    return this.dataState.pipelineExecutionDetailData?.executionDetail ?? null
  }

  public get remoteTaskExecutionId(): string | null {
    return this.pipelineExecutionDetailData?.remoteTaskExecutionId ?? null
  }

  public backToTopOnClick() {
    scroll(0, 0)
  }

  public async created() {
    await store.dispatch('data/loadData')
  }
}
</script>

<style lang="scss">
.tenant-dashboard {
  #tenant-name {
    margin-top: 24px;
    margin-bottom: 0px;
  }

  #documentation-link {
    margin-bottom: 24px;
  }

  .top-bar {
    display: flex;
    background-color: #FAFAFA;
    padding: 12px 16px;
    flex-direction: column;
    width: fit-content;

    .utc-offset {
      margin-top: 16px;
    }

    .date-time-filter {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      height: 100%;

      & > * {
        margin-right: 12px;
      }

      button {
        margin-right: 0;
      }
    }
  }

  .overall-status {
    margin-bottom: 5px;

    .staggered-bar {
      height: 36px;
    }
  }
}

#scroll-to-top-button {
  margin-top: 48px;
  margin-bottom: 48px;
  width: fit-content;
}

#step-details {
  table {
    th {
      text-align: left;

      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 30%;
      }
    }
  }
}
</style>
