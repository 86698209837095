import { router } from './router'
import { store } from './store'

export class ErrorHandler {
  public static async handleError(error: Error) {
    const httpError = /(\d{3})$/gm.exec(error.message)
    if(httpError) {
      switch(httpError[0]) {
        case '401':
          router.push('/redirect-to-login')
          break

        case '403':
          router.push({ name: 'error', query: {
            title: 'You do not have access to this resource',
            body: 'Please contact support for further information.'
          }})
          break
      }
    }

    await store.dispatch('global/stopLoadingImmediate')
  }
}