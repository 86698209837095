
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment'

export default class DateTimePicker extends Vue {
  @Prop() public readonly dateTime!: Date
  @Prop() public readonly label!: string
  @Prop() public readonly min!: Date
  @Prop() public readonly max!: Date

  private dateString: string = ''
  private timeString: string = ''

  public created() {
    this.dateString = moment.utc(this.dateTime).format('yyyy-MM-DD')
    this.timeString = moment.utc(this.dateTime).format('HH:mm')
  }

  public get localFormatted(): string {
    return moment(this.dateTime).format('yyyy-MM-DD HH:mm:ss')
  }

  public get date(): string {
    return moment.utc(this.dateTime).format('yyyy-MM-DD')
  }

  public set date(value: string) {
    this.dateString = value
    this.updateValue()
  }

  public get time(): string {
    return moment.utc(this.dateTime).format('HH:mm')
  }

  public set time(value: string) {
    console.log(value)
    this.timeString = value
    this.updateValue()
  }

  public get minDate(): string {
    return moment(this.min).format('yyyy-MM-DD')
  }

  public get maxDate(): string {
    return moment(this.max).format('yyyy-MM-DD')
  }

  private updateValue() {
    const iso = `${this.dateString}T${this.timeString}:00`;
    console.log(iso)
    this.$emit('update:date-time',moment.utc(iso).toDate())
  }
}
