
import { Vue } from 'vue-class-component'
import { CookieHelper } from '@/helpers'

export default class RedirectToLogin extends Vue {
  public onLoginClick() {
    CookieHelper.clearAllCookies()
    location.href = `${location.origin}/signin`
  }
}
