import { DateTimeHelper } from '@/helpers'
import { store } from '@/store'
import { GlobalState } from '@/store/modules'
import { NavigationGuard } from './navigationGuard'
import moment from "moment";

export const rootRedirectGuard: NavigationGuard = async (to, _, next) => {
  const globalState: GlobalState = store.state.global

  if(to.path != '/') {
    return false
  }

  if(globalState.accessToAllTenants) {
    next('/dashboard')
  } else {
    const from = moment(globalState.startDateLocal).valueOf()
    const to = moment(globalState.endDateLocal).valueOf()
    next(`/dashboard/${globalState.tenantId}/${from}/${to}`)
  }

  return true
}
