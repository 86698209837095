<template>
  <div class="staggered-bar" :class="direction" v-if="barData">
    <div v-for="cell in calculatedBarData"
        :key="cell.id" 
        :style="cell.style" 
        @mousemove="e => hoverMove(e, cell)" 
        @mouseenter="e => hoverStart(e, cell)"
        @mouseleave="e => hoverEnd(e, cell)">
      <div class="tooltip" :ref="`staggeredBarTooltip-${cell.id}`">{{ cell.trueValue }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { MathHelper } from '@/helpers'
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export class StaggeredBarData {
  public colour: string
  public value: number

  constructor(colour: string, value: number) {
    this.colour = colour
    this.value = value    
  }
}

class CalculatedBarData extends StaggeredBarData {
  public trueValue: number
  public id: number
  public direction: string

  constructor(id: number, colour: string, value: number, trueValue: number, direction: string) {
    super(colour, value)
    this.id = id
    this.trueValue = trueValue
    this.direction = direction
  }

  public get style() {
    const style = {
      backgroundColor: this.colour,
      width: 'auto',
      height: 'auto'
    }

    const size = `${Math.round(this.value)}%`
    if(this.direction == 'horizontal') {
      style.width = size
    } else {
      style.height = size
    }
    
    return style
  }
}

export default class StaggeredBar extends Vue {
  @Prop() public readonly barData!: StaggeredBarData[]
  @Prop() public readonly direction: string = 'horizontal'

  public get calculatedBarData(): CalculatedBarData[] {
    const total = this.barData.reduce((acc, cur) => acc += cur.value, 0)
    let id = 0
    return this.barData.map(data => new CalculatedBarData(id++, data.colour, MathHelper.calcPercentage(data.value, total), data.value, this.direction))
  }

  private getTooltipElement(id: number): HTMLElement {
    return this.$refs[`staggeredBarTooltip-${id}`] as HTMLElement
  }

  public hoverMove(event: MouseEvent, cell: CalculatedBarData) {
    const tooltip = this.getTooltipElement(cell.id)
    tooltip.style.left = `${event.pageX - (tooltip.clientWidth / 2)}px`
    tooltip.style.top = `${event.pageY - (tooltip.clientHeight + 10)}px`
  }
  
  public hoverStart(_: MouseEvent, cell: CalculatedBarData) {
    const tooltip = this.getTooltipElement(cell.id)
    tooltip.style.visibility = 'visible'
  }

  public hoverEnd(_: MouseEvent, cell: CalculatedBarData) {
    const tooltip = this.getTooltipElement(cell.id)
    tooltip.style.visibility = 'hidden'
  }
}
</script>

<style lang="scss">
.staggered-bar {
  display: flex;
  flex-grow: 1;

  &.horizontal {
    min-height: 20px;
    width: 100%;
    flex-direction: row;
  }

  &.vertical {
    min-width: 20px;
    height: 100%;
    flex-direction: column;
  }

  .tooltip {
    position: absolute;
    visibility: hidden;
    background: white;
    padding: 8px 16px;
    border: solid 1px black;
    border-radius: 2px;
  }

  & > div:hover {
    opacity: 0.8;
  }
}
</style>