<template>
  <div>
    <div class="jumbotron">
      <h1>Not authorized</h1>
      <p>
        You have either not logged in yet or the account you have logged in with does not have access.
        <br/>
        Please click the button below to log in.
      </p>
      <button @click="onLoginClick">Log in</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { CookieHelper } from '@/helpers'

export default class RedirectToLogin extends Vue {
  public onLoginClick() {
    CookieHelper.clearAllCookies()
    location.href = `${location.origin}/signin`
  }
}
</script>

<style lang="scss">
button {
  width: fit-content;
}
</style>